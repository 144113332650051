<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import useAuth from '/@src/composables/useAuth'
import { useUserStore } from '/@src/stores/userStore';

const userStore = useUserStore();
const userSigned = computed(() => userStore.isSigned);

const { logout, isAuthenticated, user, isLoading } = useAuth()

const isMenuOpen = ref(false)
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const closeMenu = () => {
  isMenuOpen.value = false
}

const handleLogout = () => {
  closeMenu()
  logout()
}

const handleEscKey = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && isMenuOpen.value) {
    closeMenu()
  }
}

const userEmail = computed(() => user.value?.email ?? 'Account');
const userName = computed(() => user.value?.name ?? 'Bot Boss');
const userPic = computed(() => user.value?.picture ?? '/assets/illustrations/default_avatar_boss.png');


onMounted(async () => {
  const instance = getCurrentInstance();
  if (instance) {
    const { $trackLeadConversion, $connectEmailToClick } = instance.appContext.config.globalProperties;

    $trackLeadConversion()
    $connectEmailToClick(userEmail.value)
  }
  document.addEventListener('keydown', handleEscKey)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleEscKey)
})
</script>

<template>
  <div>
    <div class="navbar-item">
      <Button
        class="burger-avatar"
        tabindex="-1"
        :icon-left="'feather:menu'"
        rounded
        @click="toggleMenu"
      >
        <PlaceloadAvatar
          v-if="isLoading"
          class="ml-2"
          size="small"
        />

        <AvatarSimple
          v-if="!isLoading"
          class="ml-2"
          :picture="userPic"
          size="small"
        />
      </Button>

      <button
        v-if="isMenuOpen"
        class="side-menu-background"
        @click="closeMenu"
      />

      <transition name="slide">
        <div
          v-if="isMenuOpen"
          class="side-menu"
        >
          <div class="menu-header">
            <div class="user">
              <PlaceloadAvatar
                v-if="isLoading"
                class="ml-2"
                size="small"
              />
              <AvatarSimple
                v-else
                class="ml-2"
                :picture="userPic"
                size="small"
              />
              <div class="name ml-4">
                <Title
                  tag="h2"
                  weight="semi"
                  narrow
                  class="pb-1"
                  :size="6"
                >
                  {{ userName }}
                </Title>
                <p class="paragraph rem-75">
                  {{ userEmail }}
                </p>
              </div>
            </div>
            <Button
              outlined
              icon-left="feather:x"
              rounded
              @click="closeMenu"
            >
              Close
            </Button>
          </div>
          <ul class="ml-3">
            <li>
              <RouterLink
                class="footer-link"
                to="/app/create"
                @click="closeMenu"
              >
                New agent
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="footer-link"
                to="/app"
                @click="closeMenu"
              >
                Dashboard
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="footer-link"
                to="/chat"
                @click="closeMenu"
              >
                My chats
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="footer-link"
                to="/app/account"
                @click="closeMenu"
              >
                Account
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="footer-link"
                to="/support"
                @click="closeMenu"
              >
                Support
              </RouterLink>
            </li>
            <li>
              <RouterLink
                class="footer-link logout"
                to="/"
                @click="handleLogout"
              >
                Log out
              </RouterLink>
            </li>
          </ul>

          <div class="copyright ml-2">
            <ThemeToggle />
            <p
              class="paragraph rem-90 footer-text medium:text-right is-6"
              data-config-id="copyright"
            >
              All rights reserved. 2024
              <span
                role="img"
                aria-label="copyright"
              >©</span>
              <a href="https://www.linkedin.com/in/ivansokolov2017/">
                Ivan Sokolov
              </a>
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  background-color: var(--card-bg-color);
  padding: 20px;
  z-index: 1000;
  border-left: 1px solid var(--card-border-color);

  @media (width <= 767px) {
    width: 100%;
    height: 100vh;
    border-left: none;
  }
}

.side-menu .menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin: 2rem 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.user {
  display: flex;
  align-items: center;
}

.name {
  display: flex;
  flex-direction: column;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-link,
:deep(.footer-link) {
  position: relative;
  font-family: var(--font);
  color: var(--medium-text);
  transition: color 0.3s;

  &::before {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    transform-origin: right center;
    height: 3px;
    border-radius: 50px;
    background: var(--primary);
    transform: scale(0, 1);
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    color: var(--primary-light-5);
    opacity: 1;

    &::before {
      transform-origin: left center;
      transform: scale(1, 1);
    }
  }
}

.footer-dark {
  .footer-link,
  :deep(.footer-link) {
    opacity: 0.8;

    &:hover {
      color: var(--primary-light-10) !important;
      opacity: 1;
    }
  }
}

.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logout {
  color: var(--danger);
}

.side-menu-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  backdrop-filter: blur(5px);
  background-color: transparent;
  border: none;
}

@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.blink {
  animation: blink 1s linear infinite;
}
</style>
