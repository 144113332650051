// src/composables/useSessionInitializer.ts

import { ref, type Ref } from 'vue'
import axios, { type AxiosResponse } from 'axios'
import { useRouter } from 'vue-router'
import useAuth from '/@src/composables/useAuth'

interface SessionResponse {
  thread_id: string
  jwt_token: string
}

export function useSessionInitializer() {
  const router = useRouter()
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user,
    loginWithPopup,
    signup,
  } = useAuth()
  const backendUrl: string = import.meta.env.VITE_BACKEND_URL
  const isLoading: Ref<boolean> = ref(false)
  const errorMessage: Ref<string | null> = ref(null)

  function saveSession(
    chatId: string,
    name: string | null,
    email: string | null,
    thread_id: string,
    jwt_token: string,
  ) {
    const key = `${chatId}_${name}_${email}`
    localStorage.setItem(key, JSON.stringify({ thread_id, jwt_token }))
  }

  function loadSession(
    chatId: string,
    name: string | null,
    email: string | null,
  ): SessionResponse | null {
    const key = `${chatId}_${name}_${email}`
    const session = localStorage.getItem(key)
    return session ? JSON.parse(session) : null
  }

  function isTokenExpired(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const expiry = payload.exp
      const now = Math.floor(Date.now() / 1000)
      return now >= expiry
    } catch (e) {
      console.error('Failed to decode token:', e)
      return true
    }
  }

  async function initializeIncognitoSession(
    assistantId: number,
    name: string | null,
    email: string | null,
  ): Promise<SessionResponse> {
    const existingSession = loadSession(assistantId.toString(), name, email)

    if (existingSession && !isTokenExpired(existingSession.jwt_token)) {
      return existingSession
    }

    isLoading.value = true
    errorMessage.value = null

    try {
      const response: AxiosResponse<SessionResponse> = await axios.post(
        '/api/v1/initialize_session/incognito',
        {
          name,
          email,
          assistant_id: String(assistantId),
        },
      )

      saveSession(
        assistantId.toString(),
        name,
        email,
        response.data.thread_id,
        response.data.jwt_token,
      )

      return response.data
    } catch (error) {
      console.error('Session initialization failed:', error)
      errorMessage.value = 'Failed to initialize session. Please try again.'
      throw error
    } finally {
      isLoading.value = false
    }
  }

  async function initializeSession(
    assistantId: string | number,
    forceNew: boolean = false,
  ): Promise<SessionResponse> {
    isLoading.value = true
    try {
      if (!isAuthenticated.value) {
        /*await loginWithPopup({
          authorizationParams: {
            screen_hint: 'signup',
          },
        })*/
        signup()
      }

      const token: string = await getAccessTokenSilently()
      const url: string = `${backendUrl}/initialize_session${
        forceNew ? '/new' : ''
      }`

      const formData = new FormData()
      formData.append('name', user.value?.name || 'Anonymous')
      formData.append(
        'email',
        user.value?.email ||
          `${user.value?.nickname}@anonymous.com` ||
          'anonymous@example.com',
      )
      formData.append('assistant_id', assistantId.toString())
      console.log('user:', user.value)

      if (user.value?.nickname) formData.append('nickname', user.value.nickname)
      if (user.value?.picture) formData.append('picture', user.value.picture)

      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }

      const response: AxiosResponse<SessionResponse> = await axios.post(
        url,
        formData,
        {
          headers,
          transformRequest: [(data) => data],
        },
      )

      return response.data
    } catch (error) {
      console.error('Session initialization failed:', error)
      errorMessage.value = 'Failed to initialize session. Please try again.'
      throw error
    } finally {
      isLoading.value = false
    }
  }

  return {
    initializeSession,
    initializeIncognitoSession,
    isLoading,
    errorMessage,
  }
}
