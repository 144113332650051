// src/stores/userStore.ts
import { defineStore } from 'pinia'
import axios from 'axios'
import { type User } from '/@src/types'
import { useStore } from '../composables/useStore'

interface SubscriptionStatus {
  isActive: boolean;
  expiresAt?: string;
}

interface OnboardingStep {
  id: string;
  label: string;
  icon: string;
  link?: string;
  isCompleted: (state: User | any) => boolean;
}

interface UserState {
  user: User | null;
  isLoading: boolean;
  subscriptionStatus: SubscriptionStatus | null;
  lastSubscriptionCheck: number;
  subscriptionCheckPromise: Promise<boolean> | null;
  isOnboardingVisible: boolean;
  stores: any[];
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: null,
    isLoading: false,
    subscriptionStatus: null,
    lastSubscriptionCheck: 0,
    subscriptionCheckPromise: null,
    isOnboardingVisible: true,
    stores: [],
  }),

  getters: {
    isAuthenticated: (state): boolean => !!state.user,
    isSigned: (state): boolean => !!state.user && state.user.signed,
    isSubscribed: (state): boolean => !!state.subscriptionStatus?.isActive,


    onboardingSteps(state): OnboardingStep[] {
      return [
        {
          id: 'trial',
          label: 'Start free trial',
          icon: 'ph:flag-banner',
          link: '/app/subscription',
          isCompleted: (user) => !!user?.stripeCustomerId,
        },
        {
          id: 'payment',
          label: 'Connect payment method',
          icon: 'ph:credit-card',
          link: '/app/account#subscription',
          isCompleted: (user) => !!user?.signed,
        },
        {
          id: 'stripe',
          label: 'Enable monetization',
          icon: 'ph:stripe-logo',
          link: '/app/account#monetization',
          isCompleted: (user) => user?.stripe_connect_status === 'active',
        },
        {
          id: 'agents',
          label: 'Create 2 agents',
          icon: 'ph:robot',
          link: '/app/create',
          isCompleted: (user) => Object.keys(user?.notifications?.assistants || {}).length >= 2,
        },
        // {
        //   id: 'agency',
        //   label: 'Start an agency',
        //   icon: 'ph:buildings',
        //   link: '/app#agencies',
        //   isCompleted: () => state.stores.length > 0,
        // },
        {
          id: 'interviews',
          label: 'Get first reports',
          icon: 'ph:chat-circle',
          link: '/app#reports',
          isCompleted: (user) => Object.values(user?.notifications?.assistants || {}).some(
            assistant => (assistant as any).new_interview > 0
          ),
        }
      ]
    },

    completedSteps(state): number {
      if (!state.user) return 0;
      return this.onboardingSteps.filter(step => {
        if (step.id === 'agency') {
          return step.isCompleted(state);
        }
        return step.isCompleted(state.user!);
      }).length;
    },

    onboardingProgress(): number {
      return (this.completedSteps / this.onboardingSteps.length) * 100;
    },
  },

  actions: {
    initializeClientState() {
      if (typeof window !== 'undefined') {
          this.isOnboardingVisible = localStorage.getItem('onboardingVisible') !== 'false'
      }
    },
    async setUser(userData: User) {
      this.user = userData
      await this.checkOnboardingStatus()
    },

    // Inside userStore.ts
    async updateStores() {
      // Add explicit authentication check
      if (!this.isAuthenticated || !this.user) {
        console.warn('Attempted to update stores without authentication')
        return
      }

      const { fetchStores } = useStore()
      try {
        const stores = await fetchStores()
        this.stores = stores || []
      } catch (error) {
        console.error('Failed to update stores:', error)
      }
    },


    setSubscriptionStatus(status: SubscriptionStatus) {
      this.subscriptionStatus = status;
    },

    async checkOnboardingStatus() {
      if (!this.isAuthenticated) return

      await this.updateStores()

      // Hide if all steps completed
      if (this.completedSteps === this.onboardingSteps.length) {
        this.isOnboardingVisible = false
        localStorage.setItem('onboardingVisible', 'false')
        return
      }

      // Show if not completed
      if (!this.isOnboardingVisible && this.completedSteps < this.onboardingSteps.length) {
        this.isOnboardingVisible = true
        localStorage.setItem('onboardingVisible', 'true')
      }
    },



    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
      localStorage.setItem('onboardingVisible', this.isOnboardingVisible.toString());
    },

    clearUser() {
      this.user = null;
      this.subscriptionStatus = null;
      this.lastSubscriptionCheck = 0;
      this.subscriptionCheckPromise = null;
    },

    async checkSubscription(token: string) {
      const now = Date.now();
      const oneHour = 60 * 60 * 1000;

      if (this.subscriptionCheckPromise) {
        return this.subscriptionCheckPromise;
      }
      if (now - this.lastSubscriptionCheck < oneHour && this.subscriptionStatus !== null) {
        return this.isSubscribed;
      }
      this.isLoading = true;
      this.subscriptionCheckPromise = new Promise(async (resolve) => {
        try {
          const response = await axios.get('/check-subscription', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.setSubscriptionStatus({
            isActive: response.data.is_active,
            expiresAt: response.data.expires_at,
          });
          this.lastSubscriptionCheck = now;
          resolve(this.isSubscribed);
        } catch (error) {
          console.error('Subscription check failed:', error);
          resolve(this.isSubscribed);
        } finally {
          this.isLoading = false;
          this.subscriptionCheckPromise = null;
        }
      });

      return this.subscriptionCheckPromise;
    },
  },
})
