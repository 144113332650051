<!-- src/components/intervu/assistants/AssistantPreviewMarketplace.vue -->

<script setup lang="ts">
import { defineProps, ref, onMounted } from 'vue';

interface Props {
  id?: number;
  botName: string;
  botDescription: string;
  botpicUrl?: string;
  telegramBotUsername?: string;
  slug?: string;
  editable?: boolean;
  internal?: boolean;
  coverImage?: string;
  humans: number;
  interviews: number;
  price: number;
  knowledge?: KnowledgeData | null;
  tools?: string;
}

interface KnowledgeData {
  documents?: {
    enabled: boolean;
    files?: Array<{
      id: string;
      path: string;
      added_at: string;
      metadata: {
        original_name: string;
        mime_type: string;
        size: number;
      };
      original_name: string;
    }>;
  };
  shared_history?: {
    enabled: boolean;
    last_update: string | null;
    store_id: string | null;
  };
}


const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  botName: '',
  botDescription: '',
  botpicUrl: '/assets/illustrations/default_botpic.svg',
  editable: false,
  internal: false,
  telegramBotUsername: '',
  slug: '',
  coverImage: '/assets/illustrations/assistant_placeholder.svg',
  humans: 0,
  interviews: 0,
  price: 0,
  knowledge: null,
  tools: '',
});

const isLoading = ref(false);
const botpicUrl = ref(props.botpicUrl);
const showGlitch = ref(false);
const showElectric = ref(false);

onMounted(() => {
  console.log(props.tools);
  // Glitch effect
  setInterval(() => {
    if (Math.random() > 0.8) {
      showGlitch.value = true;
      showElectric.value = true;
      setTimeout(() => {
        showGlitch.value = false;
        showElectric.value = false;
      }, 300);
    }
  }, 1000);

  // Electric effect
  setInterval(() => {
    if (Math.random() > 0.7) {
      showElectric.value = true;
      setTimeout(() => {
        showElectric.value = false;
      }, 2000);
    }
  }, 3000);
});


const hasCustomKnowledge = computed(() => {
  if (!props.knowledge) return false;

  return props.knowledge.documents?.enabled &&
         Array.isArray(props.knowledge.documents?.files) &&
         props.knowledge.documents.files.length > 0;
});



</script>

<template>
  <div class="assistant-item">
    <div class="cover-wrapper">
      <img
        class="cover-image"
        :class="{ 'glitch': showGlitch }"
        :src="coverImage"
        :alt="botName"
      >

      <div
        v-if="showElectric"
        class="electric-overlay"
      >
        <div class="electric-line" />
        <div class="electric-line delayed" />
        <div class="electric-line delayed-more" />
      </div>
    </div>
    <div class="card_header">
      <PlaceloadAvatar
        v-if="isLoading"
        size="small"
      />
      <AvatarSimple
        v-if="telegramBotUsername && !isLoading"
        :picture="botpicUrl"
        badge="/assets/illustrations/tg_icon.svg"
        size="small"
      />
      <AvatarSimple
        v-if="!telegramBotUsername && !isLoading"
        :picture="botpicUrl"
        size="small"
      />

      <Title
        tag="h2"
        weight="semi"
        :size="6"
        class="header-title"
      >
        {{ botName || 'My 8D-1' }}
      </Title>
    </div>
    <AssistantTag
      :knowledge="knowledge"
      :tools="tools"
    />


    <div>
      <p class="description has-text-left">
        {{ botDescription || 'This AI assistant will collect all the necessary information from you.' }}
      </p>
    </div>

    <!-- Stats and price footer -->
    <div class="card-footer">
      <div class="stats">
        <div class="stats-item">
          <i
            class="iconify"
            data-icon="prime:users"
          />
          <span class="rem-85">{{ humans || "0" }}</span>
        </div>
        <div class="stats-item">
          <i
            class="iconify"
            data-icon="prime:microchip-ai"
          />
          <span class="rem-85">GPT-4o</span>
        </div>
      </div>
      <div class="stats">
        <div class="stats-item">
          <p class="rem-85">
            Price per use:
          </p>
          <i
            class="iconify"
            data-icon="prime:dollar"
          />
          <span class="rem-85">{{ price || "0" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cover-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9; // Forces consistent image ratio
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &.glitch {
    animation: glitch 0.2s infinite;
  }
}

// Existing glitch styles...
.glitch-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 138, 255, 0.5);
  mix-blend-mode: overlay;
  pointer-events: none;
}

// New electric effect styles
.electric-overlay {
  position: absolute;
  top: 30;
  left: 30;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.electric-line {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(33, 138, 255, 0) 0%,
      var(--card-border-color) 50%,
      rgba(33, 138, 255, 0) 100%
    );
    animation: electricSlide 1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.delayed::before {
    animation: electricSlide 1s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  }

  &.delayed-more::before {
    animation: electricSlide 1s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  }
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  70% {
    transform: translate(0.5px, -0.5px);
    filter: brightness(1.1) blur(1px);
  }
  100% {
    transform: translate(0);
    filter: hue-rotate(0deg);
  }
}

@keyframes electricSlide {
  0% {
    transform: translateY(-100%) rotate(-45deg) scale(1);
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    transform: translateY(100%) rotate(-45deg) scale(2);
    opacity: 0;
  }
  45% {
    opacity: 0.2;
  }
  50% {
    transform: translateY(-100%) rotate(-45deg) scale(1);
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    transform: translateY(100%) rotate(-45deg) scale(2);
    opacity: 0;
  }
}

.card_header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--title-color);
  height: 2rem;
}

.paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  overflow: hidden;
  color: var(--paragraph-color);
}

.cover-image {
  overflow: hidden;
  width: 100%;
  height: auto;
  object-fit: cover;
}


.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  color: var(--title-color);
  padding: 0.5rem 0;
  border-top: 1px solid var(--card-border-color);
}

.stats {
  display: flex;
  gap: 1rem;
}

.stats-item {
  display: flex;
  gap: 0.1rem;
  align-items: center;
}

.assistant-item {
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  transition: transform 0.2s;
  border: 1px solid var(--card-border-color);
  border-radius: 1rem;
  background: var(--card-bg-color);

  @media (width <= 768px) {

  }
}
</style>
