import { ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useUserStore } from '/@src/stores/userStore'
import useAuth from './useAuth'

// Types
interface StoreData {
  id?: number | null
  store_name: string
  title?: string
  subtitle?: string
  active?: boolean
  logo?: string
  logo_dark?: string
  button?: {
    text: string
    link: string
  }
  testimonial?: Array<{
    text: string
    authorName: string
    title: string
    picture: {
      id: number | null
      url: string
    }
  }>
  faq?: Array<{
    title: string
    content: string
  }>
  assistants?: number[]
  about_us?: {
    title: string
    paragraph: string
    picture: {
      id: number | null
      url: string
    }
    cta: string
    link: string
  }
}

export function useStore() {
  const userStore = useUserStore()
  const router = useRouter()
  const { getAccessTokenSilently, isAuthenticated } = useAuth()

  // State
  const store = ref<StoreData | null>(null)
  const stores = ref<StoreData[]>([])
  const isLoading = ref(false)
  const error = ref<string | null>(null)

  // Strapi client for public content
  const strapi = axios.create({
    baseURL: import.meta.env.VITE_STRAPI_BASE_URL,
  })

  // Backend client for authenticated requests
  const backend = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  })

  // Helper to set auth token
  const setupAxiosAuth = async () => {
    try {
      const token = await getAccessTokenSilently()
      backend.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } catch (err) {
      console.error('Error setting up axios auth:', err)
    }
  }

  // Fetch single store data
  const fetchStore = async (storeName: string) => {
    isLoading.value = true
    error.value = null

    try {
      const response = await strapi.get(`/api/stores`, {
        params: {
          'filters[store_name][$eq]': storeName,
          'populate': {
            assistants: { fields: ['id'] },
            testimonial: {
              populate: {
                picture: { fields: ['url'] }
              }
            },
            button: true,
            faq: true,
            about_us: {
              populate: {
                picture: { fields: ['url'] }
              }
            },
          }
        }
      })

      if (!response.data.data?.length) {
        throw new Error('Store not found')
      }

      store.value = mapStoreData(response.data.data[0])
      return true
    } catch (err: any) {
      error.value = err.message || 'Error loading store data'
      return false
    } finally {
      isLoading.value = false
    }
  }

  // Fetch all stores for authenticated user
  const fetchStores = async () => {
    if (!isAuthenticated.value) {
      return []
    }

    isLoading.value = true
    error.value = null

    try {
      await setupAxiosAuth()
      const response = await backend.post('/api/v1/stores/list')
      stores.value = response.data.data.map(mapStoreData)

      userStore.stores = stores.value

      return stores.value
    } catch (err: any) {
      error.value = 'Failed to load stores'
    } finally {
      isLoading.value = false
    }
  }

  // Create new store
  const createStore = async (storeData: Partial<StoreData>) => {
    isLoading.value = true
    error.value = null

    try {
      await setupAxiosAuth()
      const response = await backend.post('/api/v1/store/create', storeData)
      const createdStore = response.data.data

      // Navigate to the new store page
      await router.push(`/agency/${createdStore.attributes.store_name}`)

      await fetchStores()
      await userStore.checkOnboardingStatus()

      return createdStore
    } catch (err: any) {
      error.value = err.response?.data?.detail || 'Failed to create store'
      throw error.value
    } finally {
      isLoading.value = false
    }
  }

  // Update store
  const updateStore = async (storeId: number, storeData: Partial<StoreData>) => {
    isLoading.value = true
    error.value = null

    try {
      await setupAxiosAuth()
      const dataToSend = { ...storeData }
      if (!dataToSend.logo) delete dataToSend.logo
      if (!dataToSend.logo_dark) delete dataToSend.logo_dark
      const response = await backend.put(`/api/v1/store/settings/${storeId}`, dataToSend)
      const updatedStore = response.data.data

      if (storeData.store_name && storeData.store_name !== store.value?.store_name) {
        await router.push(`/agency/${updatedStore.attributes.store_name}`)
      }

      await fetchStores()
      await userStore.checkOnboardingStatus()

      return updatedStore
    } catch (err: any) {
      error.value = err.response?.data?.detail || 'Failed to update store'
      throw error.value
    } finally {
      isLoading.value = false
    }
  }

  // Update store section
  const updateStoreSection = async (storeId: number, sectionData: any) => {
    isLoading.value = true
    error.value = null

    try {
      await setupAxiosAuth()

      // Format the data to match the backend API expectation
      let store_data = {}

      // Handle different section updates
      if (sectionData.about_us) {
        store_data = {
          about_us: {
            title: sectionData.about_us.title,
            paragraph: sectionData.about_us.paragraph,
            cta: sectionData.about_us.cta || '',
            link: sectionData.about_us.link || '',
            ...(sectionData.about_us.picture && {
              picture: { id: sectionData.about_us.picture.id }
            })
          }
        }
      } else if (sectionData.testimonial) {
        store_data = {
          testimonial: sectionData.testimonial.map((t: any) => ({
            text: t.text,
            authorName: t.authorName,
            title: t.title,
            ...(t.picture && { picture: { id: t.picture } })
          }))
        }
      } else if (sectionData.faq) {
        store_data = {
          faq: sectionData.faq.map((f: any) => {
            return {
              question: f.question,
              answer: f.answer
            }
          })
        }
      } else if (sectionData.assistants) {
        store_data = {
          assistants: sectionData.assistants
        }
      } else {
        // For hero section or other direct updates
        store_data = sectionData
      }

      const response = await backend.put(
        `/api/v1/store/${storeId}`,
        store_data, // Send the formatted data directly
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      return response.data
    } catch (err: any) {
      error.value = err.response?.data?.detail || 'Failed to update store section'
      throw error.value
    } finally {
      isLoading.value = false
    }
  }

  // Upload file helper
  const uploadFile = async (file: File) => {
    try {
      const formData = new FormData()

      // Set correct content type for SVG files
      if (file.name.toLowerCase().endsWith('.svg')) {
        const svgFile = new File([file], file.name, { type: 'image/svg+xml' })
        formData.append('files', svgFile)
      } else {
        formData.append('files', file)
      }

      const response = await strapi.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      if (!response.data[0]) {
        throw new Error('No file data received from upload')
      }

      const uploadedFile = response.data[0]

      return {
        id: uploadedFile.id,
        url: uploadedFile.url
      }
    } catch (err: any) {
      throw new Error(err.response?.data?.error || 'Failed to upload file')
    }
  }

  // Update the mapStoreData function in useStore.ts
  const mapStoreData = (data: any): StoreData => {
    const attrs = data.attributes || {}

    return {
      id: data.id,
      store_name: attrs.store_name || '',
      title: attrs.title || '',
      subtitle: attrs.subtitle || '',
      active: attrs.active ?? true,
      logo: attrs.logo,
      logo_dark: attrs.logo_dark,
      button: attrs.button || null,
      testimonial: attrs.testimonial?.map((t: any) => ({
        text: t.text || '',
        authorName: t.authorName || '',
        title: t.title || '',
        picture: {
          id: t.picture?.data?.id || null,
          url: t.picture?.data?.attributes?.url || ''
        }
      })) || [],
      faq: attrs.faq?.map((f: any) => ({
        title: f.question || '',
        content: f.answer || ''
      })) || [],
      assistants: attrs.assistants?.data?.map((a: any) => ({
        id: a.id,
        bot_name: a.attributes?.bot_name || '',
        botpic: {
          url: a.attributes?.botpic?.data?.attributes?.formats?.thumbnail?.url ||
               a.attributes?.botpic?.data?.attributes?.url || null
        }
      })) || [],
      about_us: attrs.about_us ? {
        title: attrs.about_us.title || '',
        paragraph: attrs.about_us.paragraph || '',
        picture: {
          id: attrs.about_us.picture?.data?.id || null,
          url: attrs.about_us.picture?.data?.attributes?.url || ''
        },
        cta: attrs.about_us.cta || '',
        link: attrs.about_us.link || ''
      } : undefined
    }
  }

  return {
    // State
    store,
    stores,
    isLoading,
    error,

    // Methods
    fetchStore,
    fetchStores,
    createStore,
    updateStore,
    updateStoreSection,
    uploadFile
  }
}
