<template>
  <div class="assistant-card">
    <PlaceloadText
      width="100%"
      height="120px"
      class="mb-5"
    />
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-5">
      <PlaceloadAvatar
        size="medium"
        class="mr-3"
      /><PlaceloadText
        width="80%"
        height="20px"
      />
    </div>
    <PlaceloadParagraph
      last-line-width="80%"
      :lines="3"
    />
  </div>
</template>

<style scoped lang="scss">
.assistant-card {
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  transition: all 0.3s;
  height: 100%;

  @media (width < 770px)  {
      padding: 0.75rem !important;
      width: calc(100% - 2rem);
  }
}
</style>
