// AssistantTag.vue
<script setup lang="ts">
interface Tag {
  id: string;
  label: string;
  color: string;
  tooltip: string;
  condition?: boolean;
}

interface Props {
  knowledge: KnowledgeData | null;
  tools?: string;
}

const props = defineProps<Props>();

const tags = computed<Tag[]>(() => [
  {
    id: 'curated',
    label: 'Curated AI',
    color: 'primary',
    tooltip: 'This agent is curated by humans'
  },
  {
    id: 'finetuned',
    label: 'Fine-tuned',
    color: 'info',
    tooltip: 'This agent has been trained on custom knowledge',
    condition: hasCustomKnowledge.value
  },
  {
    id: 'rewards',
    label: 'Token rewards',
    color: 'warning',
    tooltip: 'This agent can send token rewards on TON blockchain',
    condition: props.tools?.includes('send_ton_reward')
  },
  {
    id: 'posting',
    label: 'Auto-posting',
    color: 'success',
    tooltip: 'This agent can post to Telegram channel',
    condition: props.tools?.includes('post_to_channel')
  }
]);

const visibleTags = computed(() =>
  tags.value.filter(tag => tag.condition === undefined || tag.condition)
);

const hasCustomKnowledge = computed(() => {
  if (!props.knowledge) return false;
  return props.knowledge.documents?.enabled &&
         Array.isArray(props.knowledge.documents?.files) &&
         props.knowledge.documents.files.length > 0;
});
</script>

<template>
  <div class="tags mb-0">
    <Popover
      v-for="tag in visibleTags"
      :key="tag.id"
      :content="tag.tooltip"
      placement="top"
      arrow
      hover
    >
      <Tag
        :color="tag.color"
        class="mb-0"
      >
        {{ tag.label }}
      </Tag>
    </Popover>
  </div>
</template>

<style scoped lang="scss">
.tags {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
</style>
