<!-- src/components/intervu/assistants/FeaturedAssistants.vue -->
<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'

const props = defineProps({
  assistantIds: {
    type: Array,
    default: () => []
  },
  returnTo: {
    type: String,
    default: '/'
  },
  featured: {
    type: Boolean,
    default: false
  }
})

const assistants = ref([])
const isLoading = ref(true)
const hasMore = ref(true)
const page = ref(1)
const pageSize = 6
const sortOrder = ref('desc')

// Initialize Strapi client
const strapi = axios.create({
  baseURL: import.meta.env.VITE_STRAPI_BASE_URL,
})

// Build filters based on props
const getFilters = () => {
  // Case 1: Show specific assistants by ID
  if (props.assistantIds?.length > 0) {
    return {
      $or: [
        { id: { $in: props.assistantIds } },
        ...(props.featured ? [{ featured: true }] : [])
      ]
    }
  }

  // Case 2: Show empty if no IDs and not featured
  if (!props.featured) {
    return { id: { $eq: 0 } } // Force empty result
  }

  // Case 3: Show featured assistants
  return { featured: true }
}

const fetchAssistants = async () => {
  try {
    isLoading.value = true

    // Create filters object once to reuse
    const filters = getFilters()

    const [assistantsResponse, statsResponse] = await Promise.all([
      strapi.get('/api/assistants', {
        params: {
          'pagination[page]': page.value,
          'pagination[pageSize]': pageSize,
          'sort[createdAt]': sortOrder.value,
          'populate': ['botpic', 'cover_image', 'tools'],
          'filters': filters
        }
      }),
      strapi.get('/api/assistants/count', {
        params: {
          'filters': filters // Apply the same filters to count
        }
      })
    ]);

    const { data, meta } = assistantsResponse.data;
    const { stats } = statsResponse.data;

    const transformedAssistants = data.map(item => {
    const assistantStats = stats.find(s => s.id === item.id) || { interviews: 0, humans: 0 };

    return {
      id: item.id,
      bot_name: item.attributes.bot_name,
      bot_description: item.attributes.bot_description,
      slug: item.attributes.slug,
      price: item.attributes.price_per_use || 0,
      telegrambot_username: item.attributes.telegrambot_username,
      interviewsCount: assistantStats.interviews,
      humansCount: assistantStats.humans,
      botpic: item.attributes.botpic?.data?.attributes?.url || '/assets/illustrations/default_botpic.svg',
      cover_image: item.attributes.cover_image?.data?.attributes?.url,
      featured: item.attributes.featured,
      knowledge_base: item.attributes.knowledge_base,
      tools: item.attributes.tools?.data?.map(tool => tool.attributes.name).join(',')
    };
    });

    assistants.value = page.value === 1 ? transformedAssistants : [...assistants.value, ...transformedAssistants];
    hasMore.value = meta.pagination.page < meta.pagination.pageCount;
  } catch (error) {
    console.error('Error fetching assistants:', error);
  } finally {
    isLoading.value = false;
  }
};

// Load more function
const loadMore = () => {
  if (!isLoading.value && hasMore.value) {
    page.value++
    fetchAssistants()
  }
}

// Reset and refetch when assistantIds prop changes
watch(() => props.assistantIds, () => {
  page.value = 1
  hasMore.value = true
  fetchAssistants()
}, { deep: true })

onMounted(() => {
  fetchAssistants()
})
</script>

<template>
  <div class="container">
    <!-- Loading state -->
    <div
      v-if="isLoading && page === 1"
      class="loader-container"
    >
      <Card class="max-w-4 assistant-card">
        <PlaceloadAssistant />
      </Card>
    </div>

    <!-- Empty state -->
    <div
      v-else-if="!assistants.length"
      class="empty-state"
    >
      <p>No assistants selected</p>
    </div>

    <div class="assistants">
      <!-- Assistants grid -->
      <div
        v-for="assistant in assistants"
        :key="assistant.id"
      >
        <RouterLink
          :to="`/chat/${assistant.id}?returnUrl=${returnTo}&isWL=true`"
        >
          <AssistantPreviewMarketplace
            :id="assistant.id"
            :cover-image="assistant.cover_image"
            :bot-name="assistant.bot_name"
            :bot-description="assistant.bot_description"
            :botpic-url="assistant.botpic || '/assets/illustrations/default_botpic.svg'"
            :telegram-bot-username="assistant.telegrambot_username"
            :slug="assistant.slug"
            :editable="false"
            :price="assistant.price"
            :humans="assistant.humansCount"
            :interviews="assistant.interviewsCount"
            :knowledge="assistant.knowledge_base"
            :tools="assistant.tools"
          />
        </RouterLink>
      </div>
    </div>

    <div class="button-container">
      <Button
        v-if="hasMore"
        :loading="isLoading"
        class="mt-6"
        @click="loadMore"
      >
        Load More
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  width: 100%;
}

.loader-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.assistants {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
  gap: 2rem;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.empty-state {
  width: 100%;
  text-align: center;
  padding: 2rem;
  color: var(--light-text);
}

.assistant-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  transition: all 0.3s;
  min-height: 100%;

  :deep(.card-content) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .button {
      margin: 0;
    }
  }
}
</style>
