import { computed, ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useUserStore } from '/@src/stores/userStore'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import { useRoute } from 'vue-router'
import type { ComputedRef, Ref } from 'vue'

interface AuthComposable {
  login: (returnTo?: string) => void
  signup: (returnTo?: string) => void
  logout: () => void
  syncUserWithServer: () => Promise<void>
  isAuthenticated: ComputedRef<boolean>
  user: ComputedRef<any>
  isLoading: ComputedRef<boolean>
  isSubscribed: ComputedRef<boolean>
  isSubscriptionChecked: Ref<boolean>
  checkSubscription: () => Promise<boolean>
  getAccessTokenSilently?: () => Promise<string>
  loginWithPopup?: () => Promise<void>
}

function createDefaultAuthState(): AuthComposable {
  const isSubscriptionChecked = ref(false)
  return {
    login: () => {},
    signup: () => {},
    logout: () => {},
    syncUserWithServer: async () => {},
    isAuthenticated: computed(() => false),
    user: computed(() => null),
    isLoading: computed(() => false),
    isSubscribed: computed(() => false),
    isSubscriptionChecked,
    checkSubscription: async () => false,
  }
}

export default function useAuth(): AuthComposable {
  // Handle SSR case
  if (import.meta.env.SSR) {
    return createDefaultAuthState()
  }

  let auth0
  try {
    auth0 = useAuth0()
  } catch (error) {
    console.error('Failed to initialize Auth0:', error)
    return createDefaultAuthState()
  }

  // Check if Auth0 is properly initialized with required methods
  if (!auth0?.getAccessTokenSilently) {
    console.warn('Auth0 instance is missing required methods')
    return createDefaultAuthState()
  }

  const userStore = useUserStore()
  const route = useRoute()
  const toast = useToast()
  const isSubscriptionChecked = ref(false)
  const isAuthenticatedLocally = ref(false)

  const { getAccessTokenSilently } = auth0

  const syncUserWithServer = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const idTokenClaims = auth0.idTokenClaims.value

      if (!accessToken || !idTokenClaims) return

      const idToken = idTokenClaims.__raw

      const response = await axios.post(
        '/auth/sync',
        { id_token: idToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      userStore.setUser(response.data.user)
      if (response.data.subscription) {
        userStore.setSubscriptionStatus(response.data.subscription)
      }
      isAuthenticatedLocally.value = true
    } catch (error) {
      console.error('Error syncing user:', error)
      toast.error(
        'Error synchronizing with server. Please try logging in again.',
        {
          timeout: 3000,
          onClose: () => {
            isAuthenticatedLocally.value = false
            userStore.clearUser()
            auth0.logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          },
        }
      )
    }
  }

  const checkSubscription = async () => {
    try {
      const token = await getAccessTokenSilently()
      if (!token) return false

      const result = await userStore.checkSubscription(token)
      isSubscriptionChecked.value = true
      return result
    } catch (error) {
      console.error('Error checking subscription:', error)
      return false
    }
  }

  return {
    login: (returnTo = route.fullPath) => {
      auth0.loginWithRedirect({
        appState: { target: returnTo },
      })
    },
    signup: (returnTo = route.fullPath) => {
      auth0.loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
        appState: { target: returnTo },
      })
    },
    logout: () =>
      auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      }),
    syncUserWithServer,
    isAuthenticated: computed(() => auth0.isAuthenticated.value),
    user: computed(() => auth0.user.value),
    isLoading: computed(() => auth0.isLoading.value),
    isSubscribed: computed(() => userStore.isSubscribed),
    isSubscriptionChecked,
    checkSubscription,
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    loginWithPopup: auth0.loginWithPopup,
  }
}
